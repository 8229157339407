import { ReactNode } from 'react'
import { Badge } from 'antd'

import { STATUS, STATUS2 } from '../consts'
import type { IOrder } from '../interface'
import { useORDER_STATUS_NAME_MAP } from '../utils'

/** 订单状态显示 */
export const OrderStatusShow = ({
  isEmployee,
  orderStatus,
  after,
  ...rest // 气泡需要
}: { isEmployee: boolean; after?: ReactNode } & Pick<IOrder, 'orderStatus'>) => {
  const statusNameMap = useORDER_STATUS_NAME_MAP(isEmployee)

  let colorMap: Record<number, string | undefined>
  if (!isEmployee) {
    colorMap = {
      [STATUS.未付款]: 'red',
      [STATUS.已付款]: 'green',
      [STATUS.部分退款]: 'gold',
      [STATUS.已退款]: 'red',
      [STATUS.已取消]: 'red',

      [STATUS.ShipO审核中]: 'purple',
      [STATUS.待预报]: undefined,
      [STATUS.预报失败]: 'red',
      [STATUS.有货]: undefined,
      [STATUS.无货]: 'red',
      [STATUS.待打包]: undefined,
      [STATUS.已出库]: 'blue',

      [STATUS.已发货]: 'cyan',
      [STATUS.已签收]: 'green',

      [STATUS.已驳回]: 'red',
    }
  } else {
    colorMap = {
      [STATUS2.有货]: undefined,
      [STATUS2.无货]: 'red',

      [STATUS2.待预报]: undefined,
      [STATUS2.预报失败]: 'red',

      [STATUS2.待打印]: undefined,
      [STATUS2.打印失败]: 'red',

      [STATUS2.待推送]: undefined,
      [STATUS2.推送失败]: 'red',

      [STATUS2.待打包]: undefined,

      [STATUS2.已发货]: 'cyan',
      [STATUS2.已签收]: 'green',
    }
  }

  return (
    <Badge
      {...rest}
      color={colorMap[orderStatus] || $CSS_VAR.statusDefault}
      text={
        <>
          {statusNameMap[orderStatus] || orderStatus}
          {!!after && <span style={{ marginLeft: 2 }}>{after}</span>}
        </>
      }
    />
  )
}
