import { useEffect, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useLatest, useRequest } from 'ahooks'
import { produce } from 'immer'
import { Badge, Button, Input, message, Modal, Popover, Radio, Select, Space } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import { DownloadOutlined, InfoCircleOutlined, SyncOutlined } from '@ant-design/icons'

import { CusImage } from '@/components/CusImage'
import { CusTable, TCusTableIns } from '@/components/CusTable'
import { SPIN_DELAY } from '@/components/Loading'
import { useControllerRef } from '@/hooks/useAbortController'
import { filePick } from '@/utils/filePick'
import { DEFAULT_PAGE_SIZE, getDefaultPaginationProps } from '@/utils/pagination'
import { fileValidate } from '@/utils/utils'
import { LocalProductCell } from '../components/LocalProductCell'
import { LocalProductPickModal } from '../components/LocalProductPick'
import { SuccessAndFailResultModal } from '../components/SuccessAndFailResultModal'
import { apiGetRecords, apiGetShopProductList, apiMatch, apiSyncProduct, shopProductImport } from './apis'
import { IRecord, IReqData, IRes } from './interface'
import { popupSlot } from './utils'
import styles from './styles.module.less'

// eslint-disable-next-line react-refresh/only-export-components
export { apiGetShopProductList }
export const ProductMatch = () => {
  const { t } = useTranslation()
  const [searchParams, setURLSearchParams] = useSearchParams()
  const [matchStatus, setMatchStatus] = useState<IReqData['matchStatus']>(() => {
    const v = searchParams.get('matchStatus')
    if (!v) return
    const val = Number(v) // 读取 url 参数
    if (val === 0 || val === 1) return val
  })
  const [searchType, setSearchType] = useState<IReqData['searchType']>(1)
  const [keyword, setKeyword] = useState<IReqData['keyword']>()
  const [page, setPage] = useState<IReqData['page']>(1)
  const [size, setSize] = useState<IReqData['size']>(DEFAULT_PAGE_SIZE)

  const reqData: IReqData = { matchStatus, searchType, keyword, page, size }
  const tableRef = useRef<TCusTableIns>(null)
  const controllerRef = useControllerRef()
  const {
    loading,
    data,
    params: [param],
    run: getList,
    mutate,
  } = useRequest<IRes, [IReqData]>(
    req => {
      controllerRef.current.abort()
      controllerRef.current = new AbortController()
      return apiGetRecords(req, controllerRef.current.signal)
    },
    {
      defaultParams: [reqData],
      onBefore: () => CusTable.scrollToTop(tableRef.current),
    },
  )
  const latestRef = useLatest({ reqData })

  const changeRecord = ($key: string, changedValues: Partial<IRecord>) => {
    mutate(oldData => {
      if (!oldData) return oldData
      return produce(oldData, draft => {
        for (const item of draft.records) {
          if (item.$key === $key) {
            Object.assign(item, changedValues)
            return
          }
        }
      })
    })
  }
  const deleteRecord = ($key: string) => {
    mutate(oldData => {
      if (!oldData) return oldData
      return produce(oldData, draft => {
        let index = -1
        for (const item of draft.records) {
          index++
          if (item.$key === $key) {
            draft.records.splice(index, 1)
            return
          }
        }
      })
    })
  }

  const columns: ColumnsType<IRecord> = [
    {
      title: t('ProductMatch.shang-dian-ming-cheng'),
      render: (_v, { shopName: v }) => v,
    },
    {
      title: t('ProductMatch.shang-pin-ming-cheng'),
      render: (_v, { title: v }) => v,
    },
    {
      title: t('ProductMatch.shang-pin-tu-pian'),
      render: (_v, { shopProductImgUrl: v }) => <CusImage height={50} width={50} src={v || CusImage.defaultImg} />,
    },
    {
      title: t('ProductMatch.sku-ming-cheng'),
      render: (_v, { skuTitle: v }) => v,
    },
    {
      title: t('ProductMatch.shang-pin-sku'),
      render: (_v, { sku: v }) => v,
    },
    {
      title: t('ProductMatch.pei-dui-chan-pin'),
      render: (_v, { matchStatus, matchSkuId, matchSku, matchProductImgUrl }) => {
        if (matchStatus === 1 && matchSkuId) {
          return <LocalProductCell img={matchProductImgUrl} sku={matchSku} skuId={matchSkuId} title={null} />
        }
      },
    },
    {
      title: t('2-common.zhuang-tai'),
      width: 100,
      render: (_v, { matchStatus }) => {
        if (matchStatus === 0) {
          return <Badge color="red" text={t('ProductMatch.wei-pi-pei')} />
        }
        if (matchStatus === 1) {
          return <Badge color="green" text={t('ProductMatch.yi-pi-pei')} />
        }
      },
    },
    {
      title: t('2-common.cao-zuo'),
      width: 100,
      render: (_v, record) => {
        const { $key, sysShopId, skuId, matchStatus } = record
        if (matchStatus === 0) {
          return (
            <Button
              type="link"
              onClick={() => {
                LocalProductPickModal.open({
                  shopProductInfo: record,
                  onPick: async ({ localProduct }) => {
                    await apiMatch('post', {
                      sysShopId,
                      shopSkuId: skuId,
                      localSkuId: localProduct.skuId,
                    })
                    if (latestRef.current.reqData.matchStatus === 0) {
                      deleteRecord($key)
                    } else {
                      changeRecord($key, {
                        matchStatus: 1,
                        matchSkuId: localProduct.skuId,
                        matchSku: localProduct.sku,
                        matchProductImgUrl: localProduct.imgUrl,
                      })
                    }
                  },
                })
              }}
            >
              {t('ProductMatch.pi-pei')}
            </Button>
          )
        }
        if (matchStatus === 1) {
          return (
            <>
              <Button
                type="link"
                onClick={() => {
                  LocalProductPickModal.open({
                    shopProductInfo: record,
                    onPick: async ({ localProduct }) => {
                      if (localProduct.skuId === record.matchSkuId) return
                      await apiMatch('put', {
                        sysShopId,
                        shopSkuId: skuId,
                        localSkuId: localProduct.skuId,
                      })
                      changeRecord($key, {
                        matchSkuId: localProduct.skuId,
                        matchSku: localProduct.sku,
                        matchProductImgUrl: localProduct.imgUrl,
                      })
                    },
                  })
                }}
              >
                {t('ProductMatch.geng-huan')}
              </Button>
            </>
          )
        }
      },
    },
  ]
  /** 平台商品导入 */
  const handleImport = () => {
    filePick({
      accept: '.xlsx',
      onFilePick: ([file]) => {
        const msg = fileValidate(file, { accept: '.xlsx', max: 10 })
        if (msg) return void message.error(msg)
        Modal.confirm({
          title: t('LocalProductList.que-ren-yao-dao-ru-ma'),
          content: file.name,
          onOk: async () => {
            const result = await shopProductImport(file)
            const { failTotal, successTotal, failList } = result
            let failReason = ''
            failList.map(item => {
              failReason += `${item.sku}:${item.message}\n`
            })
            const newResult = { failReason, failTotal, successTotal }
            SuccessAndFailResultModal.open({
              result: newResult,
              onClose: () => {
                if (result.successTotal > 0) {
                  setPage(1)
                  getList({ ...reqData, page: 1 })
                }
              },
            })
          },
        })
      },
    })
  }

  useEffect(() => void setURLSearchParams(undefined, { replace: true }), [setURLSearchParams])

  return (
    <div className={styles.box}>
      <div className={styles.header}>
        <div className={styles.header_filter}>
          <Radio.Group
            className={styles.header_filter_status}
            buttonStyle="solid"
            value={matchStatus}
            onChange={e => {
              const val = e.target.value
              setMatchStatus(val)
              setPage(1)
              getList({ ...reqData, matchStatus: val, page: 1 })
            }}
          >
            <Radio.Button value={undefined}>{t('2-common.quan-bu')}</Radio.Button>
            <Radio.Button value={1}>{t('ProductMatch.yi-pi-pei')}</Radio.Button>
            <Radio.Button value={0}>{t('ProductMatch.wei-pi-pei')}</Radio.Button>
          </Radio.Group>
          <Space.Compact className={styles.header_filter_kw}>
            <Select
              popupMatchSelectWidth={false}
              options={[
                { label: t('ProductMatch.shang-pin-ming-cheng'), value: 1 },
                { label: t('ProductMatch.shang-pin-sku'), value: 2 },
              ]}
              value={searchType}
              onChange={e => {
                setSearchType(e)
                if (keyword) {
                  setPage(1)
                  getList({ ...reqData, searchType: e, page: 1 })
                }
              }}
            />
            <Input.Search
              style={{ minWidth: 300 }}
              placeholder={t('2-common.qing-shu-ru-guan-jian-ci')}
              enterButton
              allowClear
              onChange={e => setKeyword(e.target.value.trim())}
              onSearch={(val, _e, info) => {
                val = val.trim()
                if (info?.source === 'clear' && !param?.keyword) return
                setPage(1)
                getList({ ...reqData, keyword: val, page: 1 })
              }}
            />
          </Space.Compact>
        </div>
        <div className={styles.header_action}>
          <Popover
            mouseLeaveDelay={0.2}
            placement="topRight"
            content={
              <Button
                type="link"
                icon={<DownloadOutlined />}
                onClick={() => {
                  const origin = `https://shipo-erp.oss-us-east-1.aliyuncs.com`
                  const path = `/excelTemplate/store_product_import.xlsx`
                  window.open(`${origin}${path}`)
                }}
              >
                {t('2-common.excel-mo-ban-xia-zai')}
              </Button>
            }
          >
            <Button type="primary" onClick={handleImport} style={{ marginRight: '5px' }}>
              shipo平台商品导入
              <InfoCircleOutlined />
            </Button>
          </Popover>

          <Button
            type="primary"
            icon={<SyncOutlined />}
            onClick={async () => {
              Modal.confirm({
                title: t('ProductMatch.que-ren-yao-geng-xin-shang-pin-ma'),
                onOk: async () => {
                  const total = await apiSyncProduct()
                  if (!total) return void message.info(t('ProductMatch.mei-you-yao-geng-xin-de-shu-ju'))
                  message.success(t('ProductMatch.cheng-gong-geng-xin-total-tiao-shu-ju', { total }))
                  setPage(1)
                  getList({ ...reqData, page: 1 })
                },
              })
            }}
          >
            {t('ProductMatch.geng-xin-shang-pin')}
          </Button>
        </div>
      </div>

      <div className={styles.table}>
        <CusTable
          rowKey="skuId"
          loading={{ spinning: loading, delay: SPIN_DELAY }}
          dataSource={data?.records}
          columns={columns}
          ref={tableRef}
          verticalFlexLayout
          pagination={{
            ...getDefaultPaginationProps(data?.total || 0),
            current: page,
            pageSize: size,
            onChange: (page, size) => {
              setPage(page)
              setSize(size)
              getList({ ...reqData, page, size })
            },
          }}
        />
      </div>

      <popupSlot.Slot />
    </div>
  )
}
