import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { useContextSelector } from 'use-context-selector'
import _ from 'lodash'
import { Button, Dropdown, message, Modal, Popover, Space } from 'antd'
import { DownloadOutlined, DownOutlined, InfoCircleOutlined, SyncOutlined } from '@ant-design/icons'

import { filePick } from '@/utils/filePick'
import { fileValidate } from '@/utils/utils'
import { SuccessAndFailResultModal } from '../../components/SuccessAndFailResultModal'
import { apiSyncOrder } from '../apis'
import { manualOrderImport } from '../apis'
import { TAB_TYPE } from '../consts'
import { Ctx } from '../Ctx'
import { ChangeBizStatus, SubmitFulfillment } from '../operations'
import { getFlatOrderRecords } from '../utils'
interface IProps {
  setIsShow: (value: boolean) => void
}
export const Action = memo(function Action(props: IProps) {
  const { setIsShow } = props
  const { t } = useTranslation()
  const tabType = useContextSelector(Ctx, v => v.tabType)
  const keys = useContextSelector(Ctx, v => v.selectedRowKeys)
  const records = useContextSelector(Ctx, v => v.data?.records) || []
  const getList = useContextSelector(Ctx, v => v.getList)
  const refreshTabShowCounts = useContextSelector(Ctx, v => v.refreshTabShowCounts)
  /** 平台商品导入 */
  const handleImport = () => {
    filePick({
      accept: '.xlsx',
      onFilePick: ([file]) => {
        const msg = fileValidate(file, { accept: '.xlsx', max: 10 })
        if (msg) return void message.error(msg)
        Modal.confirm({
          title: t('LocalProductList.que-ren-yao-dao-ru-ma'),
          content: file.name,
          onOk: async () => {
            const result = await manualOrderImport(file)
            const { failTotal, successTotal, failList } = result
            let failReason = ''
            failList.map(item => {
              failReason += `${item.orderNumber}:${item.message}\n`
            })
            const newResult = { failReason, failTotal, successTotal }
            SuccessAndFailResultModal.open({
              result: newResult,
              onClose: () => {
                if (result.successTotal > 0) {
                  getList()
                  refreshTabShowCounts()
                }
              },
            })
          },
        })
      },
    })
  }
  const getChangeBizStatusBtn = (
    btnText: string,
    confirmTitle: string,
    toStatus: TAB_TYPE | null,
    disabledWhenHasSubOrder?: boolean,
  ) => (
    <ChangeBizStatus
      shopOrders={getFlatOrderRecords(records, keys).map(d => _.pick(d, ['sysShopId', 'sysOrderId']))}
      confirmTitle={confirmTitle}
      curBizStatus={tabType as TAB_TYPE}
      changeBizStatus={toStatus ?? undefined}
      onSuccess={() => {
        getList()
        refreshTabShowCounts()
      }}
    >
      {({ handleClick }) => (
        <Button
          type="primary"
          disabled={disabledWhenHasSubOrder ? getFlatOrderRecords(records, keys).some(d => d.$parentKey) : undefined}
          onClick={handleClick}
        >
          {btnText}
        </Button>
      )}
    </ChangeBizStatus>
  )

  return (
    <Space>
      {tabType === TAB_TYPE.待审核 && (
        <>
          {getChangeBizStatusBtn(
            t('Order.pi-liang-shen-he'),
            t('Order.que-ren-yao-pi-liang-shen-he-ma'),
            TAB_TYPE.待处理,
          )}
          {false &&
            getChangeBizStatusBtn(
              t('Order.pi-liang-ge-zhi'),
              t('Order.que-ren-yao-pi-liang-ge-zhi-ma'),
              TAB_TYPE.已搁置,
              true,
            )}
        </>
      )}

      {tabType === TAB_TYPE.待处理 && (
        <>
          <SubmitFulfillment
            shopOrders={getFlatOrderRecords(records, keys).map(d => _.pick(d, ['sysShopId', 'sysOrderId']))}
            onSuccess={() => {
              getList()
              refreshTabShowCounts()
            }}
          >
            {({ handleClick }) => (
              <Button type="primary" onClick={handleClick}>
                {t('Order.pi-liang-ti-jiao-lv-yue')}
              </Button>
            )}
          </SubmitFulfillment>
          {false &&
            getChangeBizStatusBtn(
              t('Order.pi-liang-ge-zhi'),
              t('Order.que-ren-yao-pi-liang-ge-zhi-ma'),
              TAB_TYPE.已搁置,
              true,
            )}
        </>
      )}

      {false &&
        tabType === TAB_TYPE.已搁置 &&
        getChangeBizStatusBtn(t('Order.pi-liang-huan-yuan'), t('Order.que-ren-yao-pi-liang-huan-yuan-ma'), null, true)}

      {[TAB_TYPE.待匹配, TAB_TYPE.待付款, TAB_TYPE.待审核, TAB_TYPE.待处理].includes(tabType as TAB_TYPE) && (
        <Button
          type="primary"
          icon={<SyncOutlined />}
          onClick={() => {
            Modal.confirm({
              title: t('Order.que-ren-yao-tong-bu-ding-dan-ma'),
              onOk: async () => {
                const res = await apiSyncOrder()
                if (!res?.total) return void message.info(t('Order.mei-you-yao-geng-xin-de-shu-ju'))
                message.success(t('Order.cheng-gong-geng-xin-total-tiao-shu-ju', { total: res.total }))
                getList({ page: 1 })
                refreshTabShowCounts()
              },
            })
          }}
        >
          {t('Order.tong-bu-ding-dan')}
        </Button>
      )}
      <Dropdown
        menu={{
          items: [
            { key: 1, label: '手动创建' },
            {
              key: 3,
              label: (
                <div>
                  批量导入
                  <Popover
                    mouseLeaveDelay={0.2}
                    placement="topRight"
                    content={
                      <Button
                        type="link"
                        icon={<DownloadOutlined />}
                        onClick={() => {
                          const origin = `https://shipo-erp.oss-us-east-1.aliyuncs.com`
                          const path = `/excelTemplate/import_hand_order_template _shipo.xlsx`
                          window.open(`${origin}${path}`)
                        }}
                      >
                        {t('2-common.excel-mo-ban-xia-zai')}
                      </Button>
                    }
                  >
                    <InfoCircleOutlined />
                  </Popover>
                </div>
              ),
            },
          ],
          onClick: ({ key }) => {
            if (+key === 1) {
              console.log('key', key)
              setIsShow(true)
            }
            if (+key === 3) {
              handleImport()
            }
          },
        }}
      >
        <Button type="primary">
          <Space>
            手工订单
            <DownOutlined />
          </Space>
        </Button>
      </Dropdown>
    </Space>
  )
})
