import { cssVar } from './cssVar/cssVar'

const propertyDescriptor = { enumerable: true }

Object.defineProperties(window, {
  $F_YM: {
    ...propertyDescriptor,
    value: 'YYYY-MM',
  },
  $F_YMD: {
    ...propertyDescriptor,
    value: 'YYYY-MM-DD',
  },
  $F_YMDH: {
    ...propertyDescriptor,
    value: 'YYYY-MM-DD HH',
  },
  $F_YMDHm: {
    ...propertyDescriptor,
    value: 'YYYY-MM-DD HH:mm',
  },
  $F_YMDHms: {
    ...propertyDescriptor,
    value: 'YYYY-MM-DD HH:mm:ss',
  },
  $CSS_VAR: {
    ...propertyDescriptor,
    value: cssVar,
  },
})
