import axios from 'axios'
import qs from 'qs'
import { message } from 'antd'

import { REFRESH_TO_LOGIN_PAGE_MSG, SHOW_ERR_MSG_PREFIX } from '@/consts/consts'
import { getGlobalState } from '@/globalStore/globalStore'
import { refreshToLoginPage } from '@/utils/utils'
import { getToken, removeToken, saveToken } from './token'
import { getCustomServer, getDefaultErrMsg, getPath, isAppApi } from './utils'

declare module 'axios' {
  interface AxiosRequestConfig {
    /** 隐藏异常消息 */
    $hideErrMsg?: boolean | (($res?: { code: string; data?: unknown; msg?: string | null }) => boolean)
    /** @deprecated 请使用 signal */
    cancelToken?: CancelToken
  }
  interface AxiosResponse<T = any> {
    /** response.data.data 的引用 */
    $data: T
  }
}

axios.defaults.paramsSerializer = params => {
  return qs.stringify(params, {
    arrayFormat: 'repeat', // 与后端保持一致
  })
}

/* 添加成功请求拦截器，统一逻辑处理 */
axios.interceptors.request.use(({ ...config }) => {
  const path = getPath(config)

  if (isAppApi(config) /* 安全防护 */) {
    const token = getToken()

    // 调用获取用户信息接口 & 本地无登录态
    if (path === '/linkhub_oms/user/info' && !token) {
      refreshToLoginPage()
      throw new Error(REFRESH_TO_LOGIN_PAGE_MSG) // 转成失败
    }

    const newHeaders = new axios.AxiosHeaders(config.headers)

    // 向 headers 添加语言
    const lang = getGlobalState().lang
    newHeaders.set('lang', lang === 'zh' ? 'cn' : lang)

    // 向 headers 添加登录态（注册/重置密码/获取验证码/登录，不添加）
    if (
      token &&
      !RegExp(`^/linkhub_oms/(${['sign/up', 'reset/cipher', 'email/code', 'login'].join('|')})$`).test(path)
    ) {
      newHeaders.set(...token)
    }

    // 非生产环境客户端侧允许自定义接口服务，方便后端使用界面调试
    if (import.meta.env.VITE_APP_ENV === 'staging') {
      const customServer = getCustomServer()
      if (customServer) config = { ...config, baseURL: customServer }
    }

    return { ...config, headers: newHeaders }
  }

  return config
})

/* 先添加成功响应拦截器，统一逻辑处理 */
axios.interceptors.response.use(response => {
  const path = getPath(response.config)
  const objData = Object(response.data)
  const { code, data, msg } = objData

  // response.data.data 的引用
  response.$data = 'data' in objData ? data : undefined

  // 登录态异常
  if (isAppApi(response.config)) {
    if (code >= 1000 && code < 2000) {
      removeToken()
      refreshToLoginPage({ code, email: getGlobalState().userInfo?.email })
      throw new Error(REFRESH_TO_LOGIN_PAGE_MSG) // 转成失败
    }
  }

  // 失败的响应体状态码
  if (code !== '0') {
    throw new axios.AxiosError(`${msg || getDefaultErrMsg(code)}`, 'API', response.config, response.request, response)
  }

  if (isAppApi(response.config)) {
    // 登录成功
    if (path === `/linkhub_oms/login`) saveToken(data)
    // 登出成功
    if (path === `/linkhub_oms/logout`) {
      removeToken()
      refreshToLoginPage()
    }
  }

  return response
})

/* 后添加失败响应拦截器，统一逻辑处理 */
axios.interceptors.response.use(null, err => {
  if (axios.isCancel(err)) throw err

  // AxiosError
  if (axios.isAxiosError(err)) {
    const objData = Object(err.response?.data)
    const { code, data, msg } = objData

    // response.data.data 的引用
    if (err.response) err.response.$data = 'data' in objData ? data : undefined

    // 异常消息提示
    const hideMsg = err.config?.$hideErrMsg
    if (code !== '0' && (typeof hideMsg === 'function' ? !hideMsg(err.response?.data) : !hideMsg)) {
      message.error(`${msg || getDefaultErrMsg(code)}`)
    }
  }

  // Error
  else if (err instanceof Error) {
    // 自定义异常消息提示
    if (err.message.startsWith(SHOW_ERR_MSG_PREFIX)) {
      const showErrMsg = err.message.slice(SHOW_ERR_MSG_PREFIX.length)
      if (showErrMsg) message.error(showErrMsg, 4)
    }
  }

  throw err
})
