import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMemoizedFn, useRequest } from 'ahooks'
import dayjs from 'dayjs'
import { Badge, Button, DatePicker, Input, Select, Space } from 'antd'

import { CusTable, TCusTableIns } from '@/components/CusTable'
import { SPIN_DELAY } from '@/components/Loading'
import { PageTabs } from '@/components/PageTabs'
import { useDateRangePresets } from '@/hooks/hooks'
import { useControllerRef } from '@/hooks/useAbortController'
import { DEFAULT_PAGE_SIZE, getDefaultPaginationProps } from '@/utils/pagination'
import { MerchantSelect } from '../../components/MerchantSelect'
import { apiGetRecords } from './apis'
import { IReqData, IRes, TCol } from './interface'
import styles from './styles.module.less'

export const StockFlow = ({ isEmployee = false }: { isEmployee?: boolean }) => {
  const { t } = useTranslation()
  const dateRangePresets = useDateRangePresets()
  const [activeKey, setActiveKey] = useState<'1' | '2'>('1')

  const [sku, setSku] = useState<IReqData['sku']>()
  const [businessNo, setBusinessNo] = useState<IReqData['businessNo']>()
  const [stashName, setStashName] = useState<IReqData['stashName']>()
  const [status, setStatus] = useState<IReqData['status']>()
  const [startDate, setStartDate] = useState<IReqData['startDate']>()
  const [endDate, setEndDate] = useState<IReqData['endDate']>()
  const [merchantUserIds, setMerchantUserIds] = useState<IReqData['merchantUserIds']>()
  const [page, setPage] = useState<IReqData['page']>(1)
  const [size, setSize] = useState<IReqData['size']>(DEFAULT_PAGE_SIZE)

  const reqData: IReqData = {
    sku,
    businessNo,
    stashName,
    status,
    startDate,
    endDate,
    page,
    size,
  }
  const tableRef = useRef<TCusTableIns>(null)
  const controllerRef = useControllerRef()
  const {
    loading,
    data,
    run: getList,
    mutate,
  } = useRequest<IRes, [IReqData]>(
    req => {
      controllerRef.current.abort()
      controllerRef.current = new AbortController()
      return apiGetRecords(activeKey, req, controllerRef.current.signal)
    },
    {
      manual: true,
      onBefore: () => CusTable.scrollToTop(tableRef.current),
    },
  )
  const initFetch = useMemoizedFn(() => {
    setPage(1)
    mutate(undefined)
    getList({ ...reqData, page: 1 })
  })
  useEffect(() => void initFetch(), [activeKey, initFetch])

  const columns = useMemo<TCol[]>(() => {
    return [
      {
        title: t('Warehouse.ye-wu-shi-jian'),
        width: 125,
        render: (_v, { time: v }) => v && dayjs(v).format($F_YMDHm),
      },
      {
        title: t('Warehouse.cang-ku-ming-cheng'),
        render: (_v, { stashName: v }) => v,
      },
      {
        title: t('Warehouse.ye-wu-lei-xing'),
        render: (_v, { businessType: v }) => v,
      },
      {
        title: t('Warehouse.ye-wu-dan-hao'),
        render: (_v, { businessNo: v }) => v,
      },
      {
        title: t('Warehouse.ku-cun-zhuang-tai'),
        render: (_v, { status: v }) => {
          if (v === 1) return <Badge color="green" text={t('Warehouse.he-ge-pin')} />
          if (v === 2) return <Badge color="red" text={t('Warehouse.bu-he-ge-pin')} />
          return <Badge color={$CSS_VAR.statusDefault} text={v} />
        },
      },
      {
        title: 'sku',
        render: (_v, { sku: v }) => v,
      },
      {
        title: t('Warehouse.ying-wen-pin-ming'),
        render: (_v, { productEnTitle: v }) => v,
      },
      {
        title: t('2-common.shang-jia-ming-cheng'),
        hidden: !isEmployee,
        render: (_v, { merchantUserName: v }) => v,
      },
      {
        title: activeKey === '1' ? t('Warehouse.yuan-ku-cun-liang') : t('Warehouse.yuan-fen-pei-ku-cun'),
        render: (_v, { beforeAmount: v }) => v,
      },
      {
        title: t('Warehouse.jia-jian-shu-liang'),
        render: (_v, { amount: v }) => v,
      },
      {
        title: t('Warehouse.ku-cun-jie-yu'),
        render: (_v, { afterAmount: v }) => v,
      },
    ]
  }, [activeKey, isEmployee, t])

  const handlePressEnter = () => {
    setPage(1)
    getList({ ...reqData, page: 1 })
  }

  return (
    <div className={styles.box}>
      <div className={styles.header}>
        <Space wrap>
          <Input
            placeholder={t('2-common.qing-shu-ru-guan-jian-ci')}
            allowClear
            addonBefore="SKU"
            value={sku}
            onChange={e => setSku(e.target.value)}
            onPressEnter={handlePressEnter}
          />
          <Input
            placeholder={t('2-common.qing-shu-ru-guan-jian-ci')}
            allowClear
            addonBefore={t('Warehouse.ye-wu-dan-hao')}
            value={businessNo}
            onChange={e => setBusinessNo(e.target.value)}
            onPressEnter={handlePressEnter}
          />
          <Input
            placeholder={t('2-common.qing-shu-ru-guan-jian-ci')}
            allowClear
            addonBefore={t('Warehouse.cang-ku-ming-cheng')}
            value={stashName}
            onChange={e => setStashName(e.target.value)}
            onPressEnter={handlePressEnter}
          />
          <Space size={0}>
            {t('Warehouse.ku-cun-zhuang-tai')}&nbsp;
            <Select
              style={{ width: 100 }}
              placeholder={t('2-common.qing-xuan-ze')}
              allowClear
              value={status}
              onChange={v => {
                setStatus(v)
                setPage(1)
                getList({ ...reqData, status: v, page: 1 })
              }}
              options={[
                { value: 1, label: t('Warehouse.he-ge-pin') },
                { value: 2, label: t('Warehouse.bu-he-ge-pin') },
              ]}
            />
          </Space>
          <Space>
            <Space size={0}>
              {t('Warehouse.ye-wu-shi-jian')}&nbsp;
              <DatePicker.RangePicker
                style={{ width: 215 }}
                disabledDate={curr => curr.endOf('day') > dayjs().endOf('day')}
                presets={[
                  dateRangePresets.today,
                  dateRangePresets.yesterday,
                  dateRangePresets.thisWeek,
                  dateRangePresets.lastWeek,
                  dateRangePresets.thisMonth,
                  dateRangePresets.lastMonth,
                  dateRangePresets.last7days,
                  dateRangePresets.last30days,
                  dateRangePresets.last6months,
                ]}
                value={startDate && endDate ? [dayjs(startDate), dayjs(endDate)] : undefined}
                onChange={(_dates, [s, e]) => {
                  const start = s || undefined
                  const end = e || undefined
                  setStartDate(start)
                  setEndDate(end)
                  setPage(1)
                  getList({ ...reqData, startDate: start, endDate: end, page: 1 })
                }}
              />
            </Space>
            {isEmployee && (
              <MerchantSelect
                style={{ width: 160 }}
                value={merchantUserIds?.[0]}
                onChange={val => {
                  const merchantUserIds = val ? [val] : undefined
                  setMerchantUserIds(merchantUserIds)
                  setPage(1)
                  getList({ ...reqData, merchantUserIds, page: 1 })
                }}
              />
            )}
            <Space size={4} style={{ marginLeft: 8 }}>
              <Button
                type="primary"
                onClick={() => {
                  setPage(1)
                  getList({ ...reqData, page: 1 })
                }}
              >
                {t('2-common.sou-suo')}
              </Button>
              <Button
                onClick={() => {
                  const vo = undefined
                  setSku(vo)
                  setBusinessNo(vo)
                  setStashName(vo)
                  setStatus(vo)
                  setStartDate(vo)
                  setEndDate(vo)
                  setMerchantUserIds(vo)
                  setPage(1)
                  getList({
                    ...reqData,
                    sku: vo,
                    businessNo: vo,
                    stashName: vo,
                    status: vo,
                    startDate: vo,
                    endDate: vo,
                    merchantUserIds: vo,
                    page: 1,
                  })
                }}
              >
                {t('2-common.zhong-zhi')}
              </Button>
            </Space>
          </Space>
        </Space>
      </div>

      <PageTabs
        className={styles.tabs}
        activeKey={activeKey}
        onChange={key => setActiveKey(key as '1' | '2')}
        items={[
          { key: '1', label: t('Warehouse.shi-wu-ku-cun-liu-shui') },
          { key: '2', label: t('Warehouse.fen-pei-ku-cun-liu-shui') },
        ]}
      />

      <div className={styles.table}>
        <CusTable
          rowKey="id"
          loading={{ spinning: loading, delay: SPIN_DELAY }}
          dataSource={data?.records}
          columns={columns}
          ref={tableRef}
          verticalFlexLayout
          pagination={{
            ...getDefaultPaginationProps(data?.total || 0),
            current: page,
            pageSize: size,
            onChange: (page, size) => {
              setPage(page)
              setSize(size)
              getList({ ...reqData, page, size })
            },
          }}
        />
      </div>
    </div>
  )
}
