import { t } from 'i18next'
import axios, { AxiosRequestConfig } from 'axios'

export const getPath = (config: AxiosRequestConfig) => {
  return (
    axios
      .getUri(config)
      .split(/[?#]+/)[0]
      // 去除 origin，参考 https://github.com/axios/axios/blob/v1.7.7/lib/helpers/isAbsoluteURL.js
      .replace(/^([a-z][a-z\d+\-.]*:)?\/\/[^/]+/i, '')
      // 去除尾杠
      .replace(/\/+$/, '')
  )
}

export const getCustomServer = () => {
  return sessionStorage.getItem('oms_customServer')?.match(/^https?:\/\/[^/?#]+/i)?.[0]
}

export const getDefaultErrMsg = (code: unknown) => {
  const arrivedBackEnd = typeof code === 'string' && /^-?[1-9]\d*$/.test(code)
  return arrivedBackEnd ? t('1-error.xi-tong-yi-chang') : t('1-error.qing-qiu-yi-chang')
}

export const isAppApi = (config: AxiosRequestConfig) => {
  if (import.meta.env.VITE_APP_ENV !== 'staging') {
    const path = axios.getUri(config)
    if (/^([a-z][a-z\d+\-.]*:)?\/\/[^/]+/i.test(path) && !path.startsWith(location.origin)) return false
  }
  const path = getPath(config)
  return path.startsWith('/linkhub_oms/')
}
