import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useRequest } from 'ahooks'
import dayjs from 'dayjs'
import { Badge, Button, DatePicker, Popover, Radio, Space } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import { PlusOutlined, QuestionCircleOutlined } from '@ant-design/icons'

import { CellFormatSum } from '@/components/CellFormatSum'
import { CusTable, TCusTableIns } from '@/components/CusTable'
import { SPIN_DELAY } from '@/components/Loading'
import { getGlobalState } from '@/globalStore/globalStore'
import { useDateRangePresets } from '@/hooks/hooks'
import { useControllerRef } from '@/hooks/useAbortController'
import { DEFAULT_PAGE_SIZE, getDefaultPaginationProps } from '@/utils/pagination'
import { toThousands } from '@/utils/utils'
import { MerchantSelect } from '../../components/MerchantSelect'
import { getDisabledDateRangeFn, useRule } from '../utils'
import { apiGetRecords } from './apis'
import { Balance } from './Balance'
import { DetailsDrawer } from './DetailsDrawer'
import { IRecord, IReqData, IRes } from './interface'
import { popupSlot, useRECHARGE_STATUS_NAME_MAP } from './utils'
import styles from './styles.module.less'

export const Recharge = () => {
  const { t } = useTranslation()
  const userRule = useRule()
  const dateRangePresets = useDateRangePresets()
  const rechargeStatusNameMap = useRECHARGE_STATUS_NAME_MAP()

  const [rechargeStatus, setRechargeStatus] = useState<IReqData['rechargeStatus']>(
    userRule === 'finance' ? 0 : undefined,
  )
  const [submitDateBegin, setSubmitDateBegin] = useState<IReqData['submitDateBegin']>(() =>
    dayjs().add(-89, 'day').format($F_YMD),
  )
  const [submitDateEnd, setSubmitDateEnd] = useState<IReqData['submitDateEnd']>(() => dayjs().format($F_YMD))
  const [merchantUserIds, setMerchantUserIds] = useState<IReqData['merchantUserIds']>()
  const [page, setPage] = useState<IReqData['page']>(1)
  const [size, setSize] = useState<IReqData['size']>(DEFAULT_PAGE_SIZE)

  const reqData: IReqData = {
    rechargeStatus,
    submitDateBegin,
    submitDateEnd,
    merchantUserIds,
    page,
    size,
  }
  const tableRef = useRef<TCusTableIns>(null)
  const controllerRef = useControllerRef()
  const {
    loading,
    data,
    run: getList,
    refresh,
  } = useRequest<IRes, [IReqData]>(
    req => {
      controllerRef.current.abort()
      controllerRef.current = new AbortController()
      return apiGetRecords(req, controllerRef.current.signal)
    },
    {
      defaultParams: [reqData],
      onBefore: () => CusTable.scrollToTop(tableRef.current),
    },
  )

  const columns: ColumnsType<IRecord> = [
    {
      title: t('Transaction.jiao-yi-id'),
      render: (_v, { transactionId: v }) => v,
    },
    {
      title: t('2-common.shang-jia-ming-cheng'),
      hidden: userRule === 'customer',
      render: (_v, { merchantUserName: v }) => v,
    },
    {
      title: t('Transaction.zhuan-zhang-bi-zhong'),
      width: 90,
      render: (_v, { transferCurrencyType: v }) => v,
    },
    {
      title: t('Transaction.zhuan-zhang-jin-e'),
      width: 120,
      align: 'right',
      render: (_v, { transferAmount: v }) => toThousands(v),
    },
    {
      title: t('Transaction.dao-zhang-jin-e'),
      width: 120,
      align: 'right',
      render: (_v, { receivedAmount: v, transferCurrencyType }) => (
        <CellFormatSum value={v} currency={transferCurrencyType} />
      ),
    },
    {
      title: t('Transaction.shou-xu-fei'),
      width: 120,
      align: 'right',
      render: (_v, { fee: v, feeCurrencyType }) => <CellFormatSum value={v} currency={feeCurrencyType} />,
    },
    {
      title: t('Transaction.hui-shuai'),
      width: 100,
      render: (_v, { receivedExchangeRate: v }) => v,
    },
    {
      title: t('Transaction.zhuan-zhang-ri-qi'),
      render: (_v, { transferDate: v }) => v && dayjs(v).format($F_YMD),
    },
    {
      title: t('Transaction.dao-zhang-ri-qi'),
      render: (_v, { receivedDate: v }) => v && dayjs(v).format($F_YMD),
    },
    {
      title: t('Transaction.ti-jiao-ri-qi'),
      render: (_v, { submitDate: v }) => dayjs(v).format($F_YMD),
    },
    {
      title: t('2-common.zhuang-tai'),
      width: 90,
      render: (_v, { rechargeStatus: v, refuseReason }) => {
        const tag = (
          <Badge
            color={v === 1 ? 'green' : v === 2 ? 'red' : $CSS_VAR.statusDefault}
            text={
              <>
                {rechargeStatusNameMap[v] || v}
                {v === 2 && refuseReason && <QuestionCircleOutlined style={{ marginLeft: 2 }} />}
              </>
            }
          />
        )
        if (v === 2 && refuseReason) {
          return (
            <Popover overlayStyle={{ maxWidth: 360 }} content={refuseReason}>
              {tag}
            </Popover>
          )
        }
        return tag
      },
    },
    {
      title: t('2-common.cao-zuo'),
      width: 100,
      render: (_v, record) => {
        const { transactionId, rechargeStatus } = record
        // 财务审核
        if (userRule === 'finance' && rechargeStatus === 0) {
          return (
            <Button
              type="link"
              onClick={() =>
                DetailsDrawer.open({
                  mode: 'approval',
                  props: { transactionId, onSuccess: refresh },
                })
              }
            >
              {t('Transaction.shen-pi')}
            </Button>
          )
        }
        // 商家或员工重新提交
        if (userRule !== 'finance' && rechargeStatus === 2) {
          return (
            <Button
              type="link"
              onClick={() =>
                DetailsDrawer.open({
                  mode: 'resubmit',
                  props: { transactionId, onSuccess: refresh },
                })
              }
            >
              {t('Transaction.zhong-xin-ti-jiao')}
            </Button>
          )
        }
        // 查看
        return (
          <Button type="link" onClick={() => DetailsDrawer.open({ mode: 'view', props: { transactionId } })}>
            {t('2-common.cha-kan')}
          </Button>
        )
      },
    },
  ]

  return (
    <div className={styles.box}>
      {userRule === 'customer' && (
        <div className={styles.top}>
          <Balance />
        </div>
      )}
      <div className={styles.header}>
        <div className={styles.header_filter}>
          <Space size={16}>
            <Space size={0}>
              {t('Transaction.chong-zhi-zhuang-tai')}&nbsp;
              <Radio.Group
                buttonStyle="solid"
                value={rechargeStatus}
                onChange={e => {
                  const val = e.target.value
                  setRechargeStatus(val)
                  setPage(1)
                  getList({ ...reqData, rechargeStatus: val, page: 1 })
                }}
              >
                <Radio.Button value={undefined}>{t('2-common.quan-bu')}</Radio.Button>
                <Radio.Button value={0}>{t('Transaction.shen-he-zhong')}</Radio.Button>
                <Radio.Button value={1}>{t('Transaction.yi-dao-zhang')}</Radio.Button>
                <Radio.Button value={2}>{t('Transaction.yi-ju-jue')}</Radio.Button>
              </Radio.Group>
            </Space>
            {userRule !== 'customer' && (
              <MerchantSelect
                style={{ width: 160 }}
                value={merchantUserIds?.[0]}
                onChange={val => {
                  const merchantUserIds = val ? [val] : undefined
                  setMerchantUserIds(merchantUserIds)
                  setPage(1)
                  getList({ ...reqData, merchantUserIds, page: 1 })
                }}
              />
            )}
            <Space size={0}>
              {t('Transaction.ti-jiao-ri-qi')}&nbsp;
              <DatePicker.RangePicker
                style={{ width: 215 }}
                allowClear={false}
                disabledDate={getDisabledDateRangeFn(6)}
                presets={[
                  dateRangePresets.today,
                  dateRangePresets.yesterday,
                  dateRangePresets.thisWeek,
                  dateRangePresets.lastWeek,
                  dateRangePresets.thisMonth,
                  dateRangePresets.lastMonth,
                  dateRangePresets.last7days,
                  dateRangePresets.last30days,
                  dateRangePresets.last6months,
                ]}
                value={[dayjs(submitDateBegin), dayjs(submitDateEnd)]}
                onChange={(_dates, [submitDateBegin, submitDateEnd]) => {
                  setPage(1)
                  setSubmitDateBegin(submitDateBegin)
                  setSubmitDateEnd(submitDateEnd)
                  getList({ ...reqData, submitDateBegin, submitDateEnd, page: 1 })
                }}
              />
            </Space>
          </Space>
        </div>
        <div className={styles.header_action}>
          {userRule !== 'finance' && (
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => {
                const userId = getGlobalState().userInfo!.userId
                DetailsDrawer.open({
                  mode: 'submit',
                  props: {
                    merchantUserId: userRule === 'customer' ? userId : '',
                    onSuccess: refresh,
                  },
                })
              }}
            >
              {t('Transaction.chong-zhi-ti-jiao')}
            </Button>
          )}
        </div>
      </div>

      <div className={styles.table}>
        <CusTable
          rowKey="id"
          loading={{ spinning: loading, delay: SPIN_DELAY }}
          dataSource={data?.records}
          columns={columns}
          ref={tableRef}
          verticalFlexLayout
          pagination={{
            ...getDefaultPaginationProps(data?.total || 0),
            current: page,
            pageSize: size,
            onChange: (page, size) => {
              setPage(page)
              setSize(size)
              getList({ ...reqData, page, size })
            },
          }}
        />
      </div>

      <popupSlot.Slot />
    </div>
  )
}
